// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

import './bootstrap.min.js'
import './bootstrap-select.min.js'
import './jquery.custom-file-input.js'
import './jquery.mixitup.min.js'



import './jquery.bxslider-rahisified.min.js'
import './custom.js'







Rails.start()
Turbolinks.start()
ActiveStorage.start()


	