$(document).on('turbolinks:load', function() {
    $('#client-slider .client-carousel').bxSlider({
        minSlides: 1,
        maxSlides: 5,
        slideWidth: 200,
        slideMargin: 10,
        moveSlides: 1,
        controls: false,
        pager: false,
        auto: true,
        autoStart: true,
        autoHover: true,
        infiniteLoop: true,
        speed: 4000,
        pause: 0,
        easing: 'linear',
        responsive: true,
        autoDirection: 'next',
        useCSS: false,
        preventDefaultSwipeX: false,
        preventDefaultSwipeY: true,
        touchEnabled: false,
    });
});


jQuery(window).load(function() {
    jQuery('#loader').fadeOut("slow");
});
(function($) {
    "use strict";
    new WOW().init();
    jQuery(".scroll").click(function(event) {
        event.preventDefault();
        jQuery('html,body').animate({
            scrollTop: jQuery(this.hash).offset().top
        }, 2000);
    });
   
    if (jQuery(".counter").length > 0) {
        jQuery('.counter').counterUp({
            delay: 10,
            time: 1000
        });
    }
    // jQuery('#client-slider .client-carousel').bxSlider({
    //     minSlides: 1,
    //     maxSlides: 5,
    //     slideWidth: 200,
    //     slideMargin: 10,
    //     moveSlides: 1,
    //     controls: false,
    //     pager: false,
    //     auto: true,
    //     autoStart: true,
    //     autoHover: true,
    //     infiniteLoop: true,
    //     speed: 4000,
    //     pause: 0,
    //     easing: 'linear',
    //     responsive: true,
    //     autoDirection: 'next',
    //     useCSS: false,
    //     preventDefaultSwipeX: false,
    //     preventDefaultSwipeY: true,
    //     touchEnabled: false,
    // });
   
 
    jQuery('.testimonial-slider').bxSlider({
        pagerCustom: '#bx-pager',
        mode: 'vertical',
        controls: false
    });
    jQuery(".blogSlider").bxSlider({
        nextSelector: '#slider-next',
        prevSelector: '#slider-prev',
        nextText: '<i class="fa fa-chevron-right"></i>',
        prevText: '<i class="fa fa-chevron-left"></i>',
        slideMargin: 0,
        pager: false,
        auto: true,
        autoStart: true,
        autoHover: true,
        speed: 1000,
    });

    function initialize() {
        var mapCanvas = document.getElementById('map-canvas');
        var mapOptions = {
            center: new google.maps.LatLng(29.71626, -95.50757),
            zoom: 14,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        }
        var map = new google.maps.Map(mapCanvas, mapOptions);
        var marker = new google.maps.Marker({
            map: map,
            title: "Translator",
            position: new google.maps.LatLng(29.71626, -95.50757)
        });
        var infowindow = new google.maps.InfoWindow({
            content: "Translator Template"
        });
        google.maps.event.addListener(marker, "click", function() {
            infowindow.open(map, marker);
        });
    }
    if (jQuery("#map-canvas").length > 0) {
        google.maps.event.addDomListener(window, 'load', initialize);
    }
    if (jQuery(".selectpicker").length > 0) {
        jQuery('.selectpicker').selectpicker();
    }
    if (jQuery('#prices').length > 0) {
        jQuery('#price-table-container').mixItUp({
            animation: {
                enable: false
            },
            callbacks: {
                onMixLoad: function() {
                    jQuery(this).mixItUp('setOptions', {
                        animation: {
                            enable: true,
                            effects: 'fade stagger',
                            duration: 600,
                            easing: 'ease'
                        },
                    });
                }
            },
            load: {
                filter: '.copywriting',
                sort: false
            }
        })
    }
    jQuery('.login-form').find('.form-control').each(function() {
        var targetItem = jQuery(this).parent();
        if (jQuery(this).val()) {
            jQuery(targetItem).find('label').css({
                'top': '10px',
                'fontSize': '14px'
            });
        }
    })
    jQuery('.login-form').find('.form-control').focus(function() {
        jQuery(this).parent('.input-block').addClass('focus');
        jQuery(this).parent('.input-block').addClass('forHover');
        jQuery(this).parent().find('label').animate({
            'top': '10px',
            'fontSize': '14px'
        }, 300);
    })
    jQuery('#registerform .acf-fields .acf-input-wrap input').focus(function() {
        jQuery(this).parents().find('.acf-field').addClass('focus');
         jQuery(this).parent().parent().find('.acf-label').animate({
            'top': '0px',
            'fontSize': '14px'
        }, 300);
    })
     jQuery('#registerform .acf-fields .acf-input-wrap input').blur(function() {
        jQuery(this).parents().find('.acf-field').removeClass('focus');
        if (jQuery(this).val().length == ' ') {
            jQuery(this).parent().parent().find('.acf-field').removeClass('focus');
            jQuery(this).parent().parent().find('.acf-label').animate({
            'top': '24px',
            'fontSize': '16px'
        }, 300);
        }
    })
    jQuery('.login-form').find('.form-control').blur(function() {
        jQuery(this).parent('.input-block').removeClass('forHover');
        if (jQuery(this).val().length == 0) {
            jQuery(this).parent('.input-block').removeClass('focus');
            jQuery(this).parent().find('label').animate({
                'top': '24px',
                'fontSize': '16px'
            }, 300);
        }
    })
})(jQuery);